import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import React from 'react'
import {Button} from '../../../../_gori/partials/widgets'
import moment from 'moment'

type Props = {
  show: boolean
  handleClose: () => void
  data?: any
}

const TrackingDetailModal: React.FC<Props> = ({show, handleClose, data}) => {
  const intl = useIntl()

  const handleCloseModal = () => {
    handleClose()
  }

  const handleFormatDate = (date, isAddByText = false) => {
    let dateMoment = moment(date)
    return isAddByText
      ? dateMoment.format('dddd, MMMM D, YYYY, [by] h:mmA')
      : dateMoment.format('dddd, MMMM D, YYYY, h:mmA')
  }

  return (
    <>
      <Modal
        id='gori_modal_assign_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={() => {
          handleClose()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'TRACKING_DETAIL'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className={`fw-bolder fs-2 text-purple`}>{data.status_label}</h4>
            <p className='d-none'>
              <span className='me-2 fw-bolder'>
                {intl.formatMessage({id: 'EXPECTED_DELIVERY'})}
              </span>
              {data.estimated_delivery_at ? (
                <span className='text-primary fw-bolder'>
                  {handleFormatDate(data.estimated_delivery_at, true)}
                </span>
              ) : (
                <span>N/A</span>
              )}
            </p>

            <p>{intl.formatMessage({id: 'TRACKING_DETAIL'})}:</p>
            <div className='timeline tracking-detail'>
              {data.shipment_tracking_events.map((item: any) => (
                <div className='timeline-item d-flex'>
                  <div className='timeline-marker'></div>
                  <div className='timeline-content d-flex mb-0 py-4'>
                    <p className='timeline-date w-50 mb-0 ms-4 text-gray-700 pe-3'>
                      {handleFormatDate(item.event_at)}, {item.city}, {item.state}, {item.country}
                    </p>
                    <p className='timeline-description w-50 mb-0 text-gray-700'>
                      {item.event_description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CLOSE'})}
                loadingText={intl.formatMessage({id: 'CLOSE'})}
                event={handleCloseModal}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {TrackingDetailModal}
